import "./norme.css"
import "./fullPictures.css"
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { DownloadAsset } from "./downloadAssets";

function addSpacesBeforeUppercase(str) {
        str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
        return str.replace(/([A-Z])([A-Z])/g, '$1 $2');
}

export function FullPictures(){
    const { id } = useParams();
    const [images, setImages] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    const formattedId = addSpacesBeforeUppercase(id);
    useEffect(() => {
        setLoading(true);
        axios.get(`https://mirorarcus.fr/assets/complet/${id}`)
            .then(response => {
                setImages(response.data.images);
            })
            .catch(err => {
                console.error('Error fetching images:', err);
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    if (error) {
        return (
            <div className="container-80">
                <h1>{id}</h1>
                <div>Il n'y a aucune image disponible pour {id}</div>
            </div>
        );
    }

  return (
    <div className="container-80">
        <button className="button-home"><a href="/" className="a-none">Home</a></button>
        <h1 id="title">{formattedId}</h1>
        {
            loading ? (
                <TailSpin className="full-spinner" color="#CEDCFF" />
                ) : (
                <ul className="container-ul-dwnl">
                    {images.map((image, index) => (
                    <DownloadAsset imageUrl={image} index={index} />
                ))}
                </ul>
            )
        }
    </div>
  );
}