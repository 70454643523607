import { NavLink } from "react-router-dom";

export function AffIntroAsset(props) {
    const linkAsset = "https://mirorarcus.fr/" + props.linkFolder + "#title";
    return(
    <li className="caroussel-item" key={props.id}>
        <NavLink className="navLink" to={linkAsset}>
            <img className="caroussel-picture" src={`https://mirorarcus.fr/assets/intros/${props.linkFolder}`} alt={props.folderName} />
            <p className="caroussel-title">{props.title}</p>
        </NavLink>
    </li>)
}      