import { AffIntroAsset } from "./affIntroAsset";
import { TitleListPicture } from "./titleSecond";
import "./prefAsset.css"


export function PrefAsset(){
    const pref = [
        {id: 0, title: 'Demon Slayer x Street', folderName: 'DemonSlayerXStreet'},
        {id: 1, title: 'My Hero Academia x Night', folderName: 'MhaXNight'},
        {id: 2, title: 'Fairy Tails X Magi', folderName: 'FairyTailsXMagi'},
        {id: 3, title: 'One Piece X Gladiator', folderName: 'OnePieceXGladiator'},
    ];

    const mostLiked = pref.map((theme) =>
    <AffIntroAsset
        id={theme.id}
        title={theme.title}
        linkFolder= {theme.folderName}
    />
    );

    return (
        <div className="container-80">
            <TitleListPicture title="Les préférés"/>
            <ul className="ul-pref-assets">{mostLiked}</ul>
        </div>
    )
}