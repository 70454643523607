import "../component/norme.css"

export function About (){
    return(
        <div className="container-80">
            <section id="about">
                <h1>À Propos</h1>

                <p>Bienvenue sur <span>Arcus</span>, votre source ultime pour des fonds d'écran exceptionnels générés par l'IA Midjourney. Nous croyons en la beauté de la technologie, et notre mission est de vous offrir une expérience visuelle captivante à travers des images uniques et inspirantes.</p>
            </section>

            <section id="mission">
                <h2>Notre Mission</h2>

                <p>Chez <span>Arcus</span>, nous nous efforçons de repousser les frontières de la créativité en combinant l'art et l'intelligence artificielle. Notre équipe passionnée explore les possibilités infinies de l'IA Midjourney pour créer des œuvres visuelles éblouissantes qui transcendent l'imagination.</p>
            </section>

            <section id="propositions">
                <h2>Ce Que Nous Proposons</h2>

                <ul>
                    <li><strong>Fonds d'Écran Uniques :</strong> Chaque image que vous trouvez sur notre site est le fruit d'une collaboration harmonieuse entre la technologie et l'expression artistique. Nos fonds d'écran sont conçus pour élever votre écran et stimuler votre inspiration.</li>

                    <li><strong>Génération Intelligente :</strong> Grâce à l'utilisation de l'IA Midjourney, chaque image est générée de manière intelligente, résultant en des compositions visuelles exceptionnelles et sans cesse renouvelées.</li>

                    <li><strong>Gratuit et Accessible :</strong> Nous croyons en l'accessibilité de l'art. Toutes nos images sont gratuites à télécharger, afin que vous puissiez personnaliser votre espace numérique sans aucune barrière.</li>
                </ul>
            </section>

            <section id="engagement">
                <h2>Notre Engagement</h2>

                <p>Nous sommes déterminés à vous offrir une expérience utilisateur exceptionnelle. Si vous avez des questions, des suggestions, ou si vous souhaitez simplement en savoir plus sur notre processus créatif, n'hésitez pas à nous contacter sur Tiktok ou Instagram. <span>Arcus</span> est bien plus qu'un site de fonds d'écran, c'est une communauté où la beauté et la technologie se rencontrent.</p>

                <p>Merci de faire partie de notre voyage visuel. Explorez, téléchargez et partagez la créativité avec le monde.</p>

                <p>Arcus</p>
            </section>

        </div>
    )
}