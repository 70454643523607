import "../component/norme.css"

export function Mention() {
    return (
        <div className="container-80"> 
            <h2 id="mention">Mentions Légales</h2>
            <h4>Éditeur du site :</h4>
            <p>Ferrand Alexis</p>
            
            <h4>Hébergeur du site :</h4>
            <p>Nom du service d'hébergement utilisé, par exemple, GitHub Pages, Netlify, etc.</p>
        
            <h4>Propriété intellectuelle :</h4>
            <p>Le site et son contenu sont la propriété du particulier. Toute reproduction, même partielle, est strictement interdite sans autorisation préalable.</p>
        
            <h4>Utilisation des données personnelles :</h4>
            <p>Le site ne collecte pas de données personnelles sans consentement. Aucune information personnelle n'est partagée avec des tiers.</p>
            
            <h4>Cookies :</h4>
            <p>Le site peut utiliser des cookies à des fins d'amélioration de l'expérience utilisateur. En continuant à naviguer sur le site, l'utilisateur accepte l'utilisation des cookies.</p>
        
            <h4>Responsabilité :</h4>
            <p>Le particulier décline toute responsabilité quant à l'utilisation des images générées par l'IA Midjourney. Les utilisateurs sont responsables de l'utilisation de ces images et doivent respecter les droits d'auteur et la législation en vigueur.</p>
        
            <h4>Contact :</h4>
            <p>Pour toute question ou demande, vous pouvez contacter le propriétaire du site à l'adresse e-mail suivante : [adresse e-mail de contact].</p>
        </div>
        )
}