import { useState } from "react";
import "./dowloadAsset.css"
import "./fullPictures.css"

export function DownloadAsset({ imageUrl, index }) {
    const [showOverlay, setShowOverlay] = useState(false);
  
    const url = 'http://localhost:5500/assets/asset/' + imageUrl;

    const openOverlay = () => {
      setShowOverlay(true);
    };
  
    const closeOverlay = () => {
      setShowOverlay(false);
    };
  
    return (
      <li className="container-li-dwnl" key={index}>
          <img onClick={openOverlay} className="asset-picture" src={url} alt={imageUrl} />
        {showOverlay && (
          <div className="image-overlay">
            <span className="close-button" onClick={closeOverlay}>
              &times;
            </span>
            <div className="container-full-screen">
              <img className="overlay-image" src={url} alt={imageUrl} />
              <a className="link-full-screen" href={url}>Full screen</a>
            </div>
          </div>
        )}
      </li>
    );
  }