import "./header.css"
import arcusIcone from "../assets/Arcus.png"
import { NavLink } from "react-router-dom"

function Logo() {
    return <NavLink className="header-link" to='/' ><img className="header-logo" src={arcusIcone} alt="Logo" /></NavLink>
}

function Title() {
  return <NavLink className="header-link" to='/' ><h1 className="header-title">Arcus</h1></NavLink>
}

export function Head() {
  return <header>
    <Title />
    <Logo />
  </header>
}