import "./catalogue.css"
import "./norme.css"
import { AffIntroAsset } from "./affIntroAsset";
import { TitleListPicture } from "./titleSecond";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";


export function  LastPictures() {

    const [load, setLoad] = useState(false);
    const allTheme = [
        {id: 1, title: 'Deku', folderName: 'Deku'},
        {id: 2, title: 'Demon Slayer x Street', folderName: 'DemonSlayerXStreet'},
        {id: 3, title: 'My Hero Academia x Night', folderName: 'MhaXNight'},
        {id: 4, title: 'Naruto Life', folderName: 'Naruto'},
        {id: 5, title: 'Natsu Life', folderName: 'Natsu'},
        {id: 6, title: 'Fairy Tails X Magi', folderName: 'FairyTailsXMagi'},
        {id: 7, title: 'One Piece X Gladiator', folderName: 'OnePieceXGladiator'},
        {id: 8, title: 'One Piece X Street', folderName: 'OnePieceXStreet'},
        {id: 9, title: 'Hunter X Hunter X Street', folderName: 'HunterXHunterXStreet'},
        {id: 10, title: 'Pépite', folderName: 'Pepite'},
    ];

    useEffect(() => {
        setLoad(true);
        setTimeout(() => {
          setLoad(false);
        }, 2000);
      }, []);
    
    const mostLiked = allTheme.map((theme) =>
    <AffIntroAsset
        id={theme.id}
        title={theme.title}
        linkFolder={theme.folderName}
    />
    );
    
    return (
        <div className="container-80">
            <TitleListPicture title="Le catalogue"/>
            { load ? (
                <TailSpin color="#CEDCFF"/>
            ) : (
                <ul className="container-catalogue">{mostLiked}</ul>
            )}
        </div>
    )
}