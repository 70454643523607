import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Assets } from './pages/assets';
import { Home } from './pages/home';
import { ErrorPage } from './pages/error';
import { Catalogue } from './pages/catalogue';
import { Mention } from './pages/mention';
import { About } from './pages/about';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage />,
    children : [
      {
        path : '',
        element: <Catalogue />
      },
      {  
        path: ':id',
        element: <Assets />
      },
      {  
        path: 'mention',
        element: <Mention />
      },
      {  
        path: 'about',
        element: <About />
      },
    ]
  },
])

export default function App() {
  return <div className="app">
      <RouterProvider router={router} />
    </div>
}

