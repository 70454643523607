import { FullPictures } from "../component/fullPictures";
import { PrefAsset } from "../component/prefAsset";

export function Assets() {
    return(
        <>
            <FullPictures />
            <PrefAsset />
        </>
    )
}