import { Outlet } from "react-router-dom";
import { Head } from "../component/header";
import { Foot } from "../component/footer"

export function Home(){
    return <>
        <Head/>
        <Outlet />
        <Foot />
    </>
}