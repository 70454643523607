import { Link, NavLink } from "react-router-dom"
import arcusIcone from "../assets/Arcus.png"
import insta from "../assets/insta.png"
import tiktok from "../assets/tiktok.png"
import "./footer.css"
import "./norme.css"

function Logo() {
    return <NavLink className={"container-logo"} to={"/"}><img className="footer-logo" src={arcusIcone} alt="Logo" /></NavLink>
}

function Copyright() {
    return <p>© 2023 Arcus. Tous droits réservés.</p>
}

function Nav() {
    return (
    <div className="container-nav">
        <h4>Navigate</h4>
        <Link className={"footer-navLink"} to={"/mention#mention"}>Mention</Link>
        <Link className={"footer-navLink"} to={"/" }>Home</Link>
        <Link className={"footer-navLink"} to={"/about#about"}>About</Link>
    </div>
    )
}

function Join() {
    return (
        <div className="container-join">
            <h4>Nous suivre</h4>
            <img className="footer-logo-link" src={insta} alt="Logo" />
            <a href="https://www.tiktok.com/@mirorarcus" target="_blank" rel="noopener noreferrer">
                <img className="footer-logo-link" src={tiktok} alt="Logo" />
            </a>
        </div>
    )
}

function Version({version}) {
    return <p className="version-footer">{version}</p>
}

export function Foot () {
    return (
        <footer className="container-footer">
            <div className="container-footer-bis">
                <Logo />
                <Nav />
                <Join />
            </div>
                <Copyright />
                <Version version="V. 0.1"/>
        </footer>
    )
}